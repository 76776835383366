<template>
  <div>
    <b-tabs justified active-nav-item-class="font-weight-bold text-uppercase text-dark">
      <b-tab title="Resumen">
        <b-row>
          <b-col md="12">
            <b-overlay :show="arrUnitMeasurement == 'S/D'" rounded="sm">
              <b-table-simple hover small responsive bordered caption-top>            
                <caption class="metrics-sales-caption-table">Resumen General:</caption>
                <b-thead head-variant="dark">                                           
                  <b-tr>                      
                    <b-th width="50%" class="text-center">
                      <div class="mr-1">Cantidad Items</div>                        
                    </b-th>
                    <b-th width="50%" class="text-right">
                      <div class="mr-1">Total</div>                        
                    </b-th>                          
                  </b-tr>
                </b-thead>  
                <b-tbody> 
                  <b-tr>                                       
                    <b-td class="text-center align-middle">    
                      <div class="mr-1 metrics-sales-totales-add" v-if="arrUnitMeasurement">
                        <div v-for="(item, index) in arrUnitMeasurement" :key="'detail_unit_' + index">
                          {{Intl.NumberFormat('es-AR',{ minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format( item.count )}} {{item.reference}}
                        </div>
                      </div>
                    </b-td>
                    <b-td class="text-right align-middle">
                      <div class="mr-1 metrics-sales-totales-add">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:currency}).format( this.total )}}
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>                                                                                                 
            </b-overlay>
          </b-col> 
          <b-col md="6">
            <b-overlay :show="arrItems == 'S/D'" rounded="sm">
              <b-table-simple hover small responsive bordered caption-top :key="'forceItem_' + itemsForceUpdate" class="metrics-sales-table-responsive">           
                <caption class="metrics-sales-caption-table">Ranking de los items mas vendidos</caption>         
                <b-thead head-variant="dark">                                          
                  <b-tr>                      
                    <b-th width="80%" class="text-left">
                      <div class="mr-1">Item</div>                        
                    </b-th>
                    <b-th width="20%" class="text-right">
                      <div class="mr-1">Cantidad</div>                        
                    </b-th>                          
                  </b-tr>
                </b-thead>  
                <b-tbody> 
                  <b-tr v-for="(item, index) in arrItems" :key="'detail_items_' + index">                                       
                    <b-td class="text-left align-middle">    
                      <div class="mr-1">
                        <span v-if="item.code">
                          <b>{{item.code}}</b> - 
                        </span> {{item.name}}
                      </div>
                    </b-td>
                    <b-td class="text-right align-middle">
                      <div class="mr-1 metrics-sales-totales-add">
                        {{Intl.NumberFormat('es-AR',{ minimumIntegerDigits: 1, maximumFractionDigits: 0, minimumFractionDigits: 0 }).format( item.count )}}
                      </div>
                    </b-td>
                  </b-tr>                   
                </b-tbody>
              </b-table-simple>                 
            </b-overlay>
          </b-col>
          <b-col md="6">
            
            <b-overlay :show="arrPointSale == 'S/D'" rounded="sm">
              <b-table-simple hover small responsive bordered caption-top>           
                <caption class="metrics-sales-caption-table">Totales por Puntos de Venta</caption>         
                <b-thead head-variant="dark">                                          
                  <b-tr>                      
                    <b-th width="50%" class="text-left">
                      <div class="mr-1">Punto de Venta</div>                        
                    </b-th>
                    <b-th width="50%" class="text-right">
                      <div class="mr-1">Total</div>                        
                    </b-th>                          
                  </b-tr>
                </b-thead>  
                <b-tbody> 
                  <b-tr v-for="(item, index) in arrPointSale" :key="'detail_point_' + index">  
                    <b-td class="text-left align-middle">      
                      <div class="mr-1">
                        <span>
                          <b>{{item.point_sale}}</b> - 
                        </span> {{item.reference}}
                      </div>
                    </b-td>                      
                    <b-td class="text-right align-middle">
                      <div class="mr-1 metrics-sales-totales-add">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:currency}).format( item.count )}}
                      </div>
                    </b-td>
                  </b-tr>      
                </b-tbody>
              </b-table-simple>           
            </b-overlay>            
          </b-col>          
        </b-row>  
      </b-tab>
      <b-tab title="Datos">
        <b-overlay :show="arrSource.detailItem == 'S/D'" rounded="sm">
          <b-table-simple hover small caption-top responsive>    
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Consulta</b-th>
                <b-th class="text-right">Acciones</b-th>        
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Detalle de venta de productos (productos / clientes / vendedores / mes / año)</b-td>
                <b-td class="text-right">
                  <b-button-group size="sm">
                    <b-button @click="downloadSource('detail-items')" class="mr-1">
                      <b-icon icon="download"></b-icon>
                      CSV  
                    </b-button>                  
                    <b-button @click="openPivot('detail-items')" class="mr-1">
                      <b-icon icon="table"></b-icon>
                      Pivot
                    </b-button>      
                    <b-button @click="processPivot('detail-items', true)">
                      <b-icon icon="code"></b-icon>
                      Config. Pivot
                    </b-button>                                      
                  </b-button-group>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
      </b-tab>
    </b-tabs>


    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- PIVOT -->
    <b-modal v-model="modal.pivot.active"
            header-bg-variant="dark"
            header-text-variant="white"
            size="xl">
      <div slot="modal-header">
        {{this.modal.pivot.title}}
      </div>

      <b-row>
        <b-col lg="12">
          <Pivot :dataJson="pivot.data" v-if="arrSource.detailItem.length"/> 
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.pivot.active=false">Cerrar</b-button>        
      </div>
    </b-modal>  
  </div>
</template>
<script>
  import serviceAPI from './../services'
  import Funciones from '@/handler/funciones'
  import Helper from '@/handler/helper'     
  import Pivot from '@/components/inc/pivot/index'

  export default {
    props:{
      filters: {
        type: Object,
        default: null,
      },
    },    
    components: {
      Pivot,
    },    
    data() {
      return {                
        total: 0,
        arrItems: 'S/D',        
        arrUnitMeasurement: 'S/D',
        arrSource: {
          detailItem: 'S/D',
        },
        arrPointSale: 'S/D',
        currency: 'ARS',
        itemsForceUpdate: 0,   
        modal: {
          pivot: {
            active: false,
            title: '',
          },
        },  
        pivot: {
          data: null
        }       
      }
    },
    mounted() {      
      this.getResumen()   
    },    
    methods: {          
      // RESUMEN
      getResumen() {                      
        var seller = Helper.getSeller()
        if(seller) {
          this.filters.sellers = []
          this.filters.sellers.push({
            code: Helper.getSeller().id,
            label: Helper.getSeller().name,
          })   
        }
        
        var result = serviceAPI.getResumen('sales',this.filters)        
        result.then((response) => {                   
          var data = response.data

          //source          
          this.arrSource.detailItem = data.source.detailItem             

          //currency
          if(data.currency){
            this.currency = data.currency
          }

          //total
          this.total = parseFloat(data.total)   

          //unit          
          this.arrUnitMeasurement = []
          for(const prop in data.unit) {
            this.arrUnitMeasurement.push({
              reference: prop,
              count: parseFloat(data.unit[prop]),
            })
          }          

          //point sale          
          this.arrPointSale = []
          for(const prop in data.point_sale) {
            this.arrPointSale.push({
              point_sale: data.point_sale[prop].point_sale,
              reference: data.point_sale[prop].reference,
              count: parseFloat(data.point_sale[prop].amount_total),
            })
          }     

          //items          
          this.arrItems = []
          for(const prop in data.items) {
            this.arrItems.push({
              code: data.items[prop].code,
              name: data.items[prop].name,
              count: parseFloat(data.items[prop].count),
            })
          }
          Funciones.sortArrayInteger(this.arrItems, 'count', -1)                  

          this.setItemForceUpdate()          
        })                          
      },   
      setItemForceUpdate() {
        this.itemsForceUpdate = this.itemsForceUpdate + 1
      },
     
      // DOWNLOAD
      downloadSource(type) {     
        switch(type) {
          case 'detail-items':
            Funciones.exportToCSV(this.arrSource.detailItem, 'source-sales-detail-items')                    
            break
        }
      },

      // DOWNLOAD CONFIG
      downloadConfigPivot(jsonData) {
        var encodedUri = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(jsonData));

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pivot.json");
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);                 
      },      

      // PIVOT
      openPivot() {
        this.pivot.data = this.processPivot('detail-items')
        
        this.modal.pivot.title = 'Pivot'        
        this.modal.pivot.active = true
      },      
      processPivot(type, download=false) {
        var jsonData = null

        switch(type) {
          case 'detail-items':        
            jsonData = {
              dataSource: {                  
                data: this.arrSource.detailItem,
              },
              slice: {
                rows: [
                  {
                    uniqueName: "date.Year",
                    sort: "asc",
                  },
                  {
                    uniqueName: "sellerName",
                    sort: "asc",
                  },                  
                  {
                    uniqueName: "customerName",
                    sort: "asc",
                  },                  
                  {
                    uniqueName: "itemName",
                    sort: "asc",
                  }                                    
                ],
                columns: [
                  {
                    uniqueName: "currency",
                    sort: "asc",
                  },                  
                  {
                    uniqueName: "date.Month",
                    sort: "asc",
                  }
                ],
                measures: [
                  {
                    uniqueName: "count",
                    aggregation: "sum"
                  },   
                  {
                    uniqueName: "total",
                    aggregation: "sum"
                  },                                  
                ],
                expands: {
                  expandAll: false,
                  rows: [],
                  columns: []
                },
                drills: {
                  drillAll: false
                }
              },
              options: {
                grid: {
                  type: "compact",
                  title: "",
                  showFilter: true,
                  showHeaders: true,
                  showTotals: false,
                  showGrandTotals: "off",
                  showHierarchies: true,
                  showHierarchyCaptions: true,
                  showReportFiltersArea: true
                },
                configuratorActive: false,
                configuratorButton: true,
                showAggregations: true,
                showCalculatedValuesButton: true,
                drillThrough: true,
                showDrillThroughConfigurator: true,
                sorting: "on",
                datePattern: "dd/MM/yyyy",
                dateTimePattern: "dd/MM/yyyy HH:mm:ss",
                saveAllFormats: false,
                showDefaultSlice: true,
                defaultHierarchySortName: "asc"
              },
              formats: [
                {
                  name: "",
                  thousandsSeparator: " ",
                  decimalSeparator: ".",
                  decimalPlaces: 2,
                  maxSymbols: 20,
                  currencySymbol: "",
                  currencySymbolAlign: "left",
                  nullValue: " ",
                  infinityValue: "Infinity",
                  divideByZeroValue: "Infinity"
                }
              ],
              localization: "https://cdn.webdatarocks.com/loc/es.json"                     
            }             
            break
        }

        if(!download) {
          return jsonData
        } else {
          this.downloadConfigPivot(jsonData)
        }        
      },   
    },
  }
</script>
<style scoped>
  .metrics-sales-totales-add {
    font-weight: bold;
    font-size: 15px;
  }  
  .metrics-sales-caption-table {
    font-size: 15px;
  }
  .metrics-sales-table-responsive {
    overflow: auto;
    height: 450px;    
  }
</style>